import innovation from '../assets/innovation.svg'
import reliability from '../assets/reliability1.svg'
import professionalism from '../assets/professionalism.svg'
import grades from '../assets/Grades.svg'
import effordable from '../assets/effordable.png'
import available from '../assets/available.svg'





const FData= [
    {
        title: "Proficiency",
        content: "My biggest skill is utilising the CAS calculator in a proficient and efficient way, as well as working through questions till full understanding is achieved.",
        imgsrc : innovation
    },
    {
        title: "Reliability",
        content: "I always guarantee that my students will expand their knowledge based on Australian curriculum levels along with their academic growth.",
        imgsrc : reliability
    },
    {
        title: " Good Grade",
        content: "My target is to provide a strong fundamental depth in understanding of maths and for students to achieve good grades. ",
        imgsrc: grades
    },
    {
        title: "Professionalism",
        content: "I attempt to maintain and build a friendly relationship with students and to establish a fun and respectful learning environment.",
        imgsrc: professionalism,
    },
  
    {
        title: "Availibility",
        content: " Available for sessions anywhere between 1 hour – 3 hours with flexible times, 7 days a week.",
        imgsrc: available
    },
   
    {
        title: "Effordable",
        content: "I attempt to make my services affordable with a standard fee of $50 an hour (travel fee maybe extra",
        imgsrc : effordable
    }

]
export default FData