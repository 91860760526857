import React from 'react'
import { NavLink } from 'react-router-dom'
import './Services.css'

const Services = () => {
  return (
    <>
  
 
        <div className="service_section row">
          <h2><span> My</span> Qualifications and Services</h2>
        <div className="my_services">
                <div className="card">
                <i class="fa-solid fa-user-graduate img-responsive center-block d-block mx-auto"></i>
                    <div className="private_tutoring card-body">
                      <h3 className='text-center'>My Qualifications</h3>
                      <ul class="center-block d-block mx-auto">
                     
                        <li>Achieved an ATAR of 96.75.</li>
                        <li>Achieved a raw study score of 41 in VCE Mathematical Methods.</li>
                        <li>Achieved a raw study score of 39 in VCE Specialist Maths.</li>
                        <li>Achieved a raw study score of 44 in VCE Further Maths.</li>


                      </ul>
                      
                   
                      <NavLink class="navbar-brand btn center-block d-block mx-aut" to="/Contact">
                                      Contact Me
                                    </NavLink>
                    
                    </div>
                  </div>
                <div className="card">
                    <div className="online-tutoring">
                        <i class="fa-solid fa-book-open-reader center-block d-block mx-auto"></i>
                        <div className="online_content card-body">
                        <h3 className='text-center'> Face-to-face Tutoring</h3>
                            <ul class="center-block d-block mx-auto"> 
                            <li>MI am currently offering tutoring for students from years 6 to 10, as well as year 11’s
and 12’s in VCE Mathematical Methods units 1-4. </li>
                                <li> Depending on what is most ideal for the student, these lessons can take place either
at the student’s house or at a mutual location such as a library.</li>
                            </ul>
                           
                            <NavLink class="navbar-brand btn center-block d-block mx-aut" to="/Contact">
                                      Contact Me
                                    </NavLink>
                        </div>
                      </div>
                  </div>
        </div>
        </div>
    </>
  )    
}

export default Services